<template>
  <v-row
    :id="block_id"
    v-click-outside="triggerOnFocus"
    class="py-4"
  >
    <v-col
      cols="2"
    >
      <label>Код документа</label>
      <div class="d-flex">
        <v-tooltip
          bottom
          max-width="300px"
        >
          <template #activator="{ on }">
            <v-autocomplete
              v-model="document.doc_code"
              auto-select-first
              :items="documentTypes"
              item-text="code"
              item-value="code"
              :filter="filterBySearchField"
              outlined
              dense
              hide-details="auto"
              background-color="white"
              v-on="on"
            />
          </template>
          <span>{{ docName }}</span>
        </v-tooltip>

        <v-btn
          tabindex="-1"
          min-width="36px"
          max-width="36px"
          color="#5CB7B1"
          class="ml-1 elevation-0"
          @click="showDocuments"
        >
          <v-icon>
            mdi-format-list-bulleted
          </v-icon>
        </v-btn>
      </div>
    </v-col>
    <v-col cols="1">
      <label>Признак</label>
      <v-autocomplete
        v-model="document.present_type_code"
        auto-select-first
        outlined
        dense
        hide-details="auto"
        background-color="white"
        item-text="text"
        item-value="code"
        :items="presentTypes"
      />
    </v-col>
    <v-col cols="2">
      <label>Номер</label>
      <div
        class="document__number"
      >
        <v-text-field
          v-show="document.doc_code !== '03031'"
          v-model="document.doc_number"
          outlined
          dense
          hide-details="auto"
          background-color="white"
        />
        <v-text-field
          v-show="document.doc_code === '03031'"
          :disabled="document.deal_no_required"
          :value="document.doc_number"
          outlined
          dense
          hide-details="auto"
          background-color="white"
        />
      </div>
    </v-col>
    <v-col cols="2">
      <label>Дата документа</label>
      <custom-date-picker
        v-model="document.doc_date"
        :visited.sync="fields_been_visited"
        :has-changes.sync="hasChanges"
      />
    </v-col>
    <v-col cols="3">
      <label>Рег. номер ДТ, с которым был представлен</label>
      <v-text-field
        v-model="document.previous_reg_number"
        v-mask="'#####/######/#######'"
        outlined
        dense
        hide-details="auto"
        background-color="white"
      />
    </v-col>
    <v-col cols="2">
      <label>Для товаров</label>
      <div class="d-flex">
        <v-text-field
          ref="ware_range"
          v-model="range"
          placeholder="Все товары"
          outlined
          dense
          hide-details="auto"
          background-color="white"
          @change="onRangeChange"
        />
        <v-btn
          tabindex="-1"
          min-width="36px"
          max-width="36px"
          color="#5CB7B1"
          class="ml-1 elevation-0"
        >
          <v-icon>
            mdi-format-list-bulleted
          </v-icon>
        </v-btn>
      </div>
    </v-col>
    <v-col cols="2">
      <label>Дата с</label>
      <custom-date-picker
        v-model="document.certificate_d_on"
        :visited.sync="fields_been_visited"
        :has-changes.sync="hasChanges"
      />
    </v-col>
    <v-col cols="2">
      <label>Дата по</label>
      <custom-date-picker
        v-model="document.certificate_d_off"
        :visited.sync="fields_been_visited"
        :has-changes.sync="hasChanges"
      />
    </v-col>
    <v-col cols="2">
      <label>Предоставить к</label>
      <custom-date-picker
        v-model="document.presented_date"
        :visited.sync="fields_been_visited"
        :has-changes.sync="hasChanges"
      />
    </v-col>
    <v-col cols="2">
      <label>Срок временного ввоза</label>
      <custom-date-picker
        v-model="document.keeping_date"
        :visited.sync="fields_been_visited"
        :has-changes.sync="hasChanges"
      />
    </v-col>
    <v-col cols="4" />
    <v-col cols="2">
      <label>
        Доп. информация
      </label>
      <div class="d-flex">
        <v-autocomplete
          v-model="document.add_code"
          item-value="code"
          item-text="text"
          :items="nsiPresentAddTypes"
          :filter="filterBySearchField"
          outlined
          dense
          hide-details
        />
      </div>
    </v-col>

    <v-col cols="2">
      <label>
        Вид платежа
      </label>
      <div class="d-flex">
        <v-autocomplete
          ref="payment_type"
          v-model="document.payment_type"
          auto-select-first
          outlined
          dense
          hide-details="auto"
          background-color="white"
          class="mr-1"
          item-text="search"
          item-value="code"
          :items="paymentTypes"
        />
      </div>
    </v-col>
    <v-col cols="2">
      <label>
        Cтрана выдачи документа
      </label>
      <v-autocomplete
        v-model="document.country_letter"
        item-text="text"
        item-value="letterCode"
        :filter="filterBySearchField"
        :items="countries"
        outlined
        dense
        hide-details
      />
    </v-col>
  </v-row>
</template>
<script>
import {mapGetters} from "vuex";
import blockAutoUpdateMixin from "@/mixins/block-auto-update.mixin";
import {isCorrectStartEnd, isGoodsRangeValid, trimRange} from "@/helpers/inputs";
import {convertEmptyStringsToNull} from "@/helpers/objects";
import customFieldChecker from "@/mixins/custom-field-checker";
import {filterBySearchFieldMixin} from "@/mixins/catalogs";
import CustomDatePicker from "@/components/shared/custom-date-picker.vue";
import {showCatalog} from "@/events/statistics";
import {eventBus} from "@/bus";
import {getCountryNameByCode} from "@/helpers/catalogs";

const DOCUMENT_DETAILS_DOCTYPE = 'document-details-doctype'
export default {
  components: {CustomDatePicker},
  mixins: [blockAutoUpdateMixin, customFieldChecker, filterBySearchFieldMixin],
  props: {
    id: {
      required: true,
      validator: (v) => typeof v === 'number' || v === null
    },
  },
  data() {
    return {
      block_id: 'document-details-form',
      range: "",
      document: {
        deal_no_required: "",
        declaration_id: "",
        doc_code: "",
        doc_date: "",
        doc_number: "",
        id: "",
        present_type_code: "",
        sort_index: "",
        ware_range: "",

        previous_reg_number: "",
        certificate_d_on: "",
        certificate_d_off: "",
        presented_date: "",
        keeping_date: "",
        add_code: "",
        payment_type: "",
        country_letter: "",
      },
    }
  },
  computed: {
    ...mapGetters({
      selected: 'zvt/getSelected',
      documentTypes: "catalogs/getNsiTypesOfDocument",
      presentTypes: "catalogs/getNsiPresentTypes",
      countries:"catalogs/getNsiCountries",
      nsiPresentAddTypes:"catalogs/getNsiPresentAddTypes",
      paymentTypes:"zvt/getPaymentTypes"
    }),
    presentTypesFiltered() {
      return this.presentTypes.filter(type => type.typeDeclId === 0)
    },
    docName() {
      const {name} =
      this.documentTypes.find((i) => i.code === this.document.doc_code) || {};
      return name || "";
    },
  },
  watch: {
    id: {
      handler() {
        this.setFields()
      },
      deep: true,
      immediate: true
    },
    range(nv) {
      this.validateRange(nv.replace(".", ","));
    },
  },
  created() {
    eventBus.$on(DOCUMENT_DETAILS_DOCTYPE, this.updateDocType)
  },
  beforeDestroy() {
    eventBus.$off(DOCUMENT_DETAILS_DOCTYPE, this.updateDocType)
  },
  methods: {
    updateDocType({id, code}){
      if(id === this.document.id){
        this.document.doc_code = code;
        this.readyToUpdate()
      }
    },
    readyToUpdate(){
      this.fields_been_visited = true
      this.hasChanges = true
    },
    uploadData() {
      if (!isCorrectStartEnd(this.document.ware_range)) {
        this.processing = false
        return this.$error("Неверное начало и конец диапазона товаров");
      }
      const payload = convertEmptyStringsToNull({
        ...this.document,
        country_name: getCountryNameByCode(this.countries, this.document.country_letter)
      });
      return this.$store.dispatch("zvt/updateDocument", {
        index: this.index,
        payload,
      });
    },
    showDocuments() {
      showCatalog.trigger({
        type: "doctypes",
        id: this.document.id,
        search: this.document.doc_code,
        customEventName: DOCUMENT_DETAILS_DOCTYPE
      });
    },
    onRangeChange(value) {
      this.range = trimRange(value)
    },
    validateRange(nv) {
      const result = isGoodsRangeValid(nv);
      if (result !== false) {
        this.document.ware_range = result;
        this.range = result;
      } else {
        this.$nextTick(() => {
          this.range = this.document.ware_range;
        });
      }
    },
    setFields() {
      const documents = this.selected.presented_documents ?? []
      const document = documents.find((document) => document.id === this.id)
      Object.keys(this.document).forEach(key => {
        if (key in document) this.document[key] = document[key]
      })
    }
  }
}
</script>
