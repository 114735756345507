<template>
  <v-row
    class="py-0"
    :class="{
      'teal lighten-4': isCurrentlyEdited
    }"
    @click="$emit('edit', item.id)"
  >
    <v-col cols="1">
      <v-checkbox
        class="pl-4"
        :input-value="isSelected"
        color="teal"
        hide-details="true"
        @click.stop
        @change="$emit('select', item.id)"
      />
    </v-col>
    <v-col
      align-self="center"
      cols="1"
    >
      {{ item.sort_index }}
    </v-col>
    <v-col
      align-self="center"
      cols="1"
    >
      {{ item.doc_code }}/{{ item.present_type_code }}
    </v-col>
    <v-col
      align-self="center"
      cols="4"
    >
      {{ item.doc_number }}
    </v-col>
    <v-col
      align-self="center"
      cols="2"
    >
      {{ documentDate }}
    </v-col>
    <v-col
      align-self="center"
      cols="2"
    >
      {{ wareRange }}
    </v-col>
  </v-row>
</template>
<script>
import moment from "moment";

export default {
  props:{
    selected:{
      type:Array,
      required: true
    },
    item:{
      type:Object,
      required:true
    },
    currentlyEditedId:{
      required:true,
      validator:(v) => typeof v === 'number' || v === null
    },
  },
  computed:{
    wareRange(){
      return this. item.ware_range ? `(${this.item.ware_range })` : 'ВСЕ ТОВАРЫ'
    },
    documentDate(){
      const date = moment(this.item.doc_date)
      return date.isValid() ? date.format('DD.MM.YYYY'): null
    },
    isCurrentlyEdited(){
      return this.currentlyEditedId === this.item.id
    },
    isSelected(){
      return this.selected.includes(this.item.id)
    }
  }
}
</script>

